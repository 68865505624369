import { Translate } from '@change-corgi/core/react/i18n';
import { useTracking } from '@change-corgi/core/react/tracking';
import { Link } from '@change-corgi/design-system/components/actions';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { createModalHook } from '@change-corgi/design-system/modals';
import { Heading, Text } from '@change-corgi/design-system/typography';

import { useTrackView } from 'src/app/shared/hooks/tracking';

import { RecommendedDecisionMakerList } from './components/RecommendedDecisionMakerList';

type RecommendedDecisionMaker = {
	normalizedId: string;
	canonicalPetitionTargetId: string | null;
	name: string;
	type: 'PERSON' | 'ORG';
	title: string | null;
	photoUrl: string | null;
};

type ModalOptions = {
	petitionSlug: string;
	petitionId: string;
	recommendedDecisionMakers: RecommendedDecisionMaker[];
	trackingContext: string;
	addDmEmailConfig: {
		enabledPercent: number;
		blockedEmailProviders: string[];
	};
};

export const useDecisionMakerRecommenderModal = createModalHook<ModalOptions>({
	name: 'DecisionMakerRecommenderModal',
	title: () => (
		<Heading as="div" size="h3">
			<Translate value="corgi.components.dm_recommender.modal.title" />
		</Heading>
	),
	body: function DMRecommenderModalBody({
		options: { recommendedDecisionMakers, trackingContext, petitionSlug, petitionId, addDmEmailConfig },
	}) {
		const track = useTracking();

		useTrackView('dm_modal_view', { context: trackingContext });

		const handleNoDmClick = () => {
			void track('dm_modal_other_dm_click', { context: trackingContext });
		};

		return (
			<Box mt={16}>
				<Text>
					<Translate value="corgi.components.dm_recommender.modal.description" />
				</Text>
				<Box mt={24}>
					<Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '8px' }}>
						<Text fontWeight="bold">
							<Translate value="corgi.components.dm_recommender.modal.subtitle" />
						</Text>
						<Flex
							backgroundColor="primary-changeRed"
							sx={{ alignItems: 'center', justifyContent: 'center', px: 8, borderRadius: 4 }}
						>
							<Text color="typography-lightPrimary" size="micro" fontWeight="bold" sx={{ textTransform: 'uppercase' }}>
								<Translate value="corgi.components.dm_recommender.modal.beta_tag" />
							</Text>
						</Flex>
					</Box>
				</Box>
				<RecommendedDecisionMakerList
					petitionId={petitionId}
					recommendedDecisionMakers={recommendedDecisionMakers}
					trackingContext={trackingContext ?? 'unknown'}
					addDmEmailConfig={addDmEmailConfig}
				/>
				<Flex mt={24} sx={{ alignItems: 'center', flexDirection: 'column' }}>
					<Text size="small">
						<Translate value="corgi.components.dm_recommender.modal.add_custom_dm.body" />
					</Text>
					<Link sx={{ fontSize: '14px' }} to={`p/${petitionSlug}/edit#decision_makers`} onClick={handleNoDmClick}>
						<Translate value="corgi.components.dm_recommender.modal.add_custom_dm.link" />
					</Link>
				</Flex>
			</Box>
		);
	},
	stylesOverrides: {
		width: [null, null, 530],
		paddingX: 30,
		paddingY: 30,
	},
});
