/* eslint-disable max-lines-per-function */
import { useCallback, useState } from 'react';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import type { MutationState } from 'src/app/shared/utils/async';

import { editPetitionDecisionMakers } from './api';
import { ERROR_MAP } from './constants';

type PetitionResponse = Awaited<ReturnType<typeof editPetitionDecisionMakers>>;

type RequestState = MutationState<{ data: PetitionResponse }, { error: string }>;

type RecommendedDecisionMaker = {
	normalizedId: string;
	canonicalPetitionTargetId: string | null;
	name: string;
	type: 'PERSON' | 'ORG';
	title: string | null;
	photoUrl: string | null;
};

type RecommendedDecisionMakerOptions = {
	recommendedDecisionMaker: RecommendedDecisionMaker;
};

type UseAddRecommendedDecisionMakersOptions = {
	onSuccess?: (petition: PetitionResponse, { recommendedDecisionMaker }: RecommendedDecisionMakerOptions) => void;
	onError?: (error: string, { recommendedDecisionMaker }: RecommendedDecisionMakerOptions) => void;
};

type MutateReturn = Promise<Extract<RequestState, { status: 'loaded' } | { status: 'error' }>>;

type UseAddRecommendedDecisionMakersReturn = RequestState & {
	mutate: (
		petitionId: string,
		targetRecommendedDecisionMaker: RecommendedDecisionMaker,
		trackingContext: string,
	) => MutateReturn;
	reset: () => void;
};

export function useAddRecommendedDecisionMakers({
	onSuccess,
	onError,
}: UseAddRecommendedDecisionMakersOptions = {}): UseAddRecommendedDecisionMakersReturn {
	const utilityContext = useUtilityContext();

	const [state, setState] = useState<RequestState>({ status: 'idle' });

	const handleAddDecisionMaker = useCallback(
		async (petitionId: string, recommendedDecisionMaker: RecommendedDecisionMaker, trackingContext: string) => {
			setState({ status: 'loading' });
			try {
				const result = await editPetitionDecisionMakers(
					{ petitionId, recommendedDecisionMaker, trackingContext },
					utilityContext,
				);
				const successStatus = { status: 'loaded' as const, data: result };
				setState(successStatus);

				onSuccess?.(result, { recommendedDecisionMaker });
				return successStatus;
			} catch (e) {
				const errorMessage = e instanceof Error && e.message ? e.message : ERROR_MAP.UNKNOWN_ERROR;
				const errorStatus = { status: 'error' as const, error: errorMessage };
				setState(errorStatus);
				onError?.(errorMessage, { recommendedDecisionMaker });
				return errorStatus;
			}
		},
		[onError, onSuccess, utilityContext],
	);

	const handleReset = useCallback(() => {
		setState({ status: 'idle' });
	}, []);

	return {
		mutate: handleAddDecisionMaker,
		reset: handleReset,
		...state,
	};
}
