export const ERROR_MAP: Record<string, string> = {
	BAD_REQUEST: 'bad_request',
	FORBIDDEN: 'forbidden',
	NOT_FOUND: 'not_found',
	OTHER_ERROR: 'other_error',
	UNAUTHORIZED: 'authentication_error',
	GRAPHQL_ERROR: 'graphql_error',
	UNKNOWN_ERROR: 'unknown_error',
	NO_DATA: 'no response data',
};

export const ERROR_EVENT_NAME = 'add-recommended-petition-decision-makers';
