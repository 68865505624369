import type { ComponentProps } from 'react';

import type { RecommendedDecisionMakerList } from '../../../index';

type RecommendedDecisionMaker = ComponentProps<
	typeof RecommendedDecisionMakerList
>['recommendedDecisionMakers'][number];

type Status = 'idle' | 'loading' | 'loaded' | 'error';
type Data = RecommendedDecisionMaker;
type Error = { type: 'ADD' | 'REMOVE' };
type State =
	| { data: Data; status: Exclude<Status, 'error'> }
	| { data: Data; status: Extract<Status, 'error'>; error: Error };

type RecommendedDecisionMakerListState = Record<string, State>;

export const useInitialState = (dmList: RecommendedDecisionMaker[]): RecommendedDecisionMakerListState => {
	const state = dmList.reduce(
		(result, recommendedDecisionMaker) => ({
			...result,
			[recommendedDecisionMaker.normalizedId]: { data: recommendedDecisionMaker, status: 'idle' },
		}),
		{},
	);

	return state;
};
