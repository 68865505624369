import { Flex } from '@change-corgi/design-system/layout';

import { RecommendedDecisionMakerCard } from './components/RecommendedDecisionMakerCard';
import { useRecommendedDecisionMakerList } from './hooks/useRecommendedDecisionMakerList';

type RecommendedDecisionMaker = {
	normalizedId: string;
	canonicalPetitionTargetId: string | null;
	name: string;
	type: 'PERSON' | 'ORG';
	title: string | null;
	photoUrl: string | null;
};

type RecommendedDecisionMakerListProps = {
	recommendedDecisionMakers: RecommendedDecisionMaker[];
	petitionId: string;
	trackingContext: string;
	addDmEmailConfig: {
		enabledPercent: number;
		blockedEmailProviders: string[];
	};
};
export function RecommendedDecisionMakerList({
	recommendedDecisionMakers,
	petitionId,
	trackingContext,
	addDmEmailConfig,
}: RecommendedDecisionMakerListProps): React.JSX.Element {
	const { data, actions } = useRecommendedDecisionMakerList(recommendedDecisionMakers, petitionId, trackingContext);
	return (
		<Flex mt={16} sx={{ gap: '16px', alignItems: 'stretch', flexDirection: 'column' }}>
			{recommendedDecisionMakers.map((dm) => {
				const dmId = dm.normalizedId;
				const state = data.recommendedDecisionMakerListState;
				return (
					<RecommendedDecisionMakerCard
						key={dmId}
						state={state[dmId]}
						onAdd={actions.addRecommendedDecisionMaker}
						onRemove={actions.removeRecommendedDecisionMaker}
						addDmEmailConfig={addDmEmailConfig}
						trackingContext={trackingContext}
						petitionId={petitionId}
					/>
				);
			})}
		</Flex>
	);
}
