import merge from 'lodash/merge';

import type { useInitialState } from './useInitialState';

import type { useRecommendedDecisionMakerList } from './index';

type RecommendedDecisionMaker = Parameters<typeof useRecommendedDecisionMakerList>[0][number];
type RecommendedDecisionMakerListState = ReturnType<typeof useInitialState>;

type ActionType = 'ADD' | 'REMOVE';
type ActionStatus = 'LOADING' | 'SUCCESS' | 'ERROR';
type Action = { type: `${ActionType}_DECISION_MAKER_${ActionStatus}`; payload: RecommendedDecisionMaker };

// eslint-disable-next-line max-lines-per-function
export function recommendedDecisionMakerListReducer(
	recommendedDecisionMakerListState: RecommendedDecisionMakerListState,
	action: Action,
): RecommendedDecisionMakerListState {
	const dmId = action.payload.normalizedId;
	switch (action.type) {
		case 'ADD_DECISION_MAKER_LOADING': {
			return merge({}, recommendedDecisionMakerListState, {
				[dmId]: {
					status: 'loading',
				},
			});
		}
		case 'ADD_DECISION_MAKER_SUCCESS': {
			return merge({}, recommendedDecisionMakerListState, {
				[dmId]: {
					data: { ...recommendedDecisionMakerListState[dmId].data, ...action.payload },
					status: 'loaded',
				},
			});
		}
		case 'ADD_DECISION_MAKER_ERROR': {
			return merge({}, recommendedDecisionMakerListState, {
				[dmId]: {
					status: 'error',
					error: {
						type: 'ADD',
					},
				},
			});
		}
		case 'REMOVE_DECISION_MAKER_LOADING': {
			return merge({}, recommendedDecisionMakerListState, {
				[dmId]: {
					status: 'loading',
				},
			});
		}
		case 'REMOVE_DECISION_MAKER_SUCCESS': {
			return merge({}, recommendedDecisionMakerListState, {
				[dmId]: {
					status: 'idle',
				},
			});
		}
		case 'REMOVE_DECISION_MAKER_ERROR': {
			return merge({}, recommendedDecisionMakerListState, {
				[dmId]: {
					status: 'error',
					error: {
						type: 'REMOVE',
					},
				},
			});
		}
		default: {
			return recommendedDecisionMakerListState;
		}
	}
}
