import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { AddDecisionMakerContactMutation, AddDecisionMakerContactMutationVariables } from './api.graphql';
import { AddDecisionMakerContact } from './api.graphql';

export type CreateDMContactInput = AddDecisionMakerContactMutationVariables['input'];

export type CreateDMContactResult = { success: boolean };

export async function createDMContactApi(
	{
		input,
	}: {
		input: CreateDMContactInput;
	},
	{ gql: { fetch } }: UtilityContext,
): Promise<CreateDMContactResult> {
	try {
		const { data } = await fetch<AddDecisionMakerContactMutation, AddDecisionMakerContactMutationVariables>({
			query: AddDecisionMakerContact,
			variables: {
				input,
			},
		});
		if (data?.createDecisionMakerContact?.status === 'SUCCESS') return { success: true };
		return { success: false };
	} catch (e) {
		return { success: false };
	}
}
