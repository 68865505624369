export const ADD_CLICK_TRACKING_KEY = 'dm_modal_add_click';
export const REMOVE_CLICK_TRACKING_KEY = 'dm_modal_remove_click';
export const ADD_EMAIL_VIEW_TRACKING_KEY = 'dm_modal_add_email_view';
export const ADD_EMAIL_CLICK_TRACKING_KEY = 'dm_modal_add_email_click';
export const ADD_EMAIL_SUCCESS_TRACKING_KEY = 'dm_modal_add_email_success';
export const ADD_EMAIL_ERROR_TRACKING_KEY = 'dm_modal_add_email_error';
export const EDIT_EMAIL_VIEW_TRACKING_KEY = 'dm_modal_edit_email_view';
export const EDIT_EMAIL_INTENT_TRACKING_KEY = 'dm_modal_edit_email_intent';
export const EDIT_EMAIL_CLICK_TRACKING_KEY = 'dm_modal_edit_email_click';
export const EDIT_EMAIL_SUCCESS_TRACKING_KEY = 'dm_modal_edit_email_success';
export const EDIT_EMAIL_ERROR_TRACKING_KEY = 'dm_modal_edit_email_error';
