/* eslint-disable max-lines-per-function */
import { useEffect, useMemo, useState } from 'react';

import isEmail from 'validator/lib/isEmail';

import { useTracking } from '@change-corgi/core/react/tracking';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useCurrentUserId } from 'src/app/shared/hooks/session';

import {
	ADD_EMAIL_CLICK_TRACKING_KEY,
	ADD_EMAIL_ERROR_TRACKING_KEY,
	ADD_EMAIL_SUCCESS_TRACKING_KEY,
	ADD_EMAIL_VIEW_TRACKING_KEY,
	EDIT_EMAIL_CLICK_TRACKING_KEY,
	EDIT_EMAIL_ERROR_TRACKING_KEY,
	EDIT_EMAIL_INTENT_TRACKING_KEY,
	EDIT_EMAIL_SUCCESS_TRACKING_KEY,
	EDIT_EMAIL_VIEW_TRACKING_KEY,
} from '../../../../hooks/useRecommendedDecisionMakerList/constants';
import type { RecommendedDecisionMaker } from '../../index';

import { createDMContactApi } from './addEmail/api';
import { getDMContactApi } from './getEmail/api';
import { updateDMContactApi } from './updateEmail/api';

export type RecommendedDecisionMakerEmailState = {
	step: 'none' | 'add' | 'edit';
	status: 'idle' | 'loading' | 'success' | 'error';
	email: string;
	error?: string;
};

type UseRecommendedDecisionMakerEmailReturn = ModelHookResult<
	{
		emailState: RecommendedDecisionMakerEmailState;
		isAddEmailEnabled: boolean;
		shouldShowAddEmail: boolean;
	},
	{
		onAddEmailView: () => void;
		onAddEmail: () => Promise<void>;
		onEditEmail: () => Promise<void>;
		onEditEmailIntent: () => void;
		onChangeEmail: (email: string) => void;
	}
>;

export function useRecommendedDecisionMakerEmail(
	decisionMaker: RecommendedDecisionMaker,
	petitionId: string,
	trackingContext: string,
	addDmEmailConfig: {
		enabledPercent: number;
		blockedEmailProviders: string[];
	},
): UseRecommendedDecisionMakerEmailReturn {
	const utilityContext = useUtilityContext();
	const currentUserId = useCurrentUserId();

	const track = useTracking();

	const [emailState, setEmailState] = useState<RecommendedDecisionMakerEmailState>({
		step: 'none',
		status: 'idle',
		email: '',
	});
	const [hasExistingEmail, setHasExistingEmail] = useState(false);
	const [shouldShowAddEmail, setShouldShowAddEmail] = useState(false);

	const isAddEmailEnabled = useMemo(
		() => addDmEmailConfig.enabledPercent > Number(currentUserId) % 100,
		[addDmEmailConfig, currentUserId],
	);

	useEffect(() => {
		if (!isAddEmailEnabled) setShouldShowAddEmail(false);
		else if (emailState.step === 'none') setShouldShowAddEmail(!hasExistingEmail);
		else setShouldShowAddEmail(emailState.status !== 'success');
	}, [emailState, emailState.status, emailState.step, hasExistingEmail, isAddEmailEnabled]);

	const isABlockedProvider = (email: string) => {
		const mailServer = email.split('@')[1].split('.')[0];
		return addDmEmailConfig.blockedEmailProviders?.includes(mailServer);
	};

	const validateEmail = (email?: string) => {
		if (!email) {
			return { valid: false, error: 'Email is required' };
		}

		if (!isEmail(email)) {
			return { valid: false, error: 'Please enter a valid email' };
		}

		if (isABlockedProvider(email)) {
			return { valid: false, error: 'Please enter an official email' };
		}
		return { valid: true };
	};

	const trackDecisionMakerEmailClick = (trackingName: string): void => {
		void track(trackingName, {
			dm_id: decisionMaker.canonicalPetitionTargetId || undefined,
			dm_title: decisionMaker.title || undefined,
			context: trackingContext,
			petition_id: petitionId,
		});
	};

	useEffect(() => {
		const getEmail = async () => {
			if (decisionMaker.canonicalPetitionTargetId) {
				const email = await getDMContactApi(decisionMaker.canonicalPetitionTargetId, utilityContext);

				setHasExistingEmail(!!email);
			}
		};

		void getEmail();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addRecommendedDecisionMakerView = () => {
		if (emailState.step === 'none') {
			trackDecisionMakerEmailClick(ADD_EMAIL_VIEW_TRACKING_KEY);
			setEmailState({ ...emailState, step: 'add', status: 'idle' });
		} else {
			trackDecisionMakerEmailClick(EDIT_EMAIL_VIEW_TRACKING_KEY);
			setEmailState({ ...emailState, step: 'edit', status: 'idle' });
		}
	};

	const addRecommendedDecisionMakerEmail = async (): Promise<void> => {
		trackDecisionMakerEmailClick(ADD_EMAIL_CLICK_TRACKING_KEY);
		if (!currentUserId || !decisionMaker.canonicalPetitionTargetId) {
			setEmailState({ ...emailState, step: 'add', status: 'error', error: 'Failed. Please try again.' });

			return;
		}

		setEmailState({ ...emailState, step: 'add', status: 'loading' });

		const validation = validateEmail(emailState.email);
		if (validation.valid) {
			const { success } = await createDMContactApi(
				{
					input: {
						petitionId,
						decisionMakerId: decisionMaker.canonicalPetitionTargetId,
						type: 'email',
						value: emailState.email,
						userId: currentUserId,
					},
				},
				utilityContext,
			);

			if (success) {
				trackDecisionMakerEmailClick(ADD_EMAIL_SUCCESS_TRACKING_KEY);
				setEmailState({ ...emailState, step: 'add', status: 'success' });
			} else {
				setEmailState({ ...emailState, step: 'add', status: 'error', error: 'Failed. Please try again.' });
				setTimeout(() => {
					setEmailState({ ...emailState, step: 'add', status: 'idle' });
				}, 5000);
			}
		} else {
			trackDecisionMakerEmailClick(ADD_EMAIL_ERROR_TRACKING_KEY);
			setEmailState({ ...emailState, step: 'add', status: 'error', error: validation.error });
			setTimeout(() => {
				setEmailState({ ...emailState, step: 'add', status: 'idle' });
			}, 5000);
		}
	};

	const editRecommendedDecisionMakerEmailIntent = (): void => {
		trackDecisionMakerEmailClick(EDIT_EMAIL_INTENT_TRACKING_KEY);
		setEmailState({ step: 'edit', status: 'idle', email: emailState.email });
	};

	const editRecommendedDecisionMakerEmail = async (): Promise<void> => {
		trackDecisionMakerEmailClick(EDIT_EMAIL_CLICK_TRACKING_KEY);
		if (!currentUserId || !decisionMaker.canonicalPetitionTargetId) {
			setEmailState({ ...emailState, step: 'edit', status: 'error', error: 'Failed. Please try again.' });

			return;
		}

		setEmailState({ ...emailState, step: 'edit', status: 'loading' });

		const validation = validateEmail(emailState.email);
		if (validation.valid) {
			const { success } = await updateDMContactApi(
				{
					input: {
						decisionMakerId: decisionMaker.canonicalPetitionTargetId,
						type: 'email',
						value: emailState.email,
					},
				},
				utilityContext,
			);

			if (success) {
				trackDecisionMakerEmailClick(EDIT_EMAIL_SUCCESS_TRACKING_KEY);
				setEmailState({ ...emailState, step: 'edit', status: 'success' });
			} else {
				setEmailState({ ...emailState, step: 'edit', status: 'error', error: 'Failed. Please try again.' });
				setTimeout(() => {
					setEmailState({ ...emailState, step: 'edit', status: 'idle' });
				}, 5000);
			}
		} else {
			trackDecisionMakerEmailClick(EDIT_EMAIL_ERROR_TRACKING_KEY);
			setEmailState({ ...emailState, step: 'edit', status: 'error', error: validation.error });
			setTimeout(() => {
				setEmailState({ ...emailState, step: 'edit', status: 'idle' });
			}, 5000);
		}
	};

	return {
		data: { emailState, isAddEmailEnabled, shouldShowAddEmail },
		actions: {
			onAddEmailView: addRecommendedDecisionMakerView,
			onAddEmail: addRecommendedDecisionMakerEmail,
			onEditEmail: editRecommendedDecisionMakerEmail,
			onEditEmailIntent: editRecommendedDecisionMakerEmailIntent,
			onChangeEmail: (email: string) =>
				setEmailState({ ...emailState, step: emailState.step === 'none' ? 'add' : emailState.step, email }),
		},
	};
}
