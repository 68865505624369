import type { ChangeEvent } from 'react';
import React, { useEffect } from 'react';

import { Button } from '@change-corgi/design-system/components/actions';
import { Input } from '@change-corgi/design-system/components/forms';
import { Text } from '@change-corgi/design-system/typography';

import { InfoTooltip } from 'src/app/shared/components/tooltips';
import { isLoading } from 'src/app/shared/utils/async';

import type { RecommendedDecisionMakerEmailState } from '../../hooks/useRecommendedDecisionMakerEmail';
import type { RecommendedDecisionMaker } from '../../index';

type AddEmailProps = {
	recommendedDecisionMaker: RecommendedDecisionMaker;
	onAddEmailView: () => void;
	onAddEmail: () => void;
	onEditEmail: () => void;
	onChangeEmail: (email: string) => void;
	emailState: RecommendedDecisionMakerEmailState;
};

export function AddEmail({
	recommendedDecisionMaker,
	onAddEmailView,
	onAddEmail,
	onEditEmail,
	emailState,
	onChangeEmail,
}: AddEmailProps): React.JSX.Element | null {
	useEffect(() => {
		onAddEmailView();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Text my={24} size="small" as="p">
				Help us find the contact information for the decision maker.{' '}
				<InfoTooltip>Emails for decision makers help us notify them about your petition.</InfoTooltip>
			</Text>
			<Input
				id={`dm-add-email-${recommendedDecisionMaker.normalizedId}`}
				label="Email (optional)"
				value={emailState.email}
				onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeEmail(e.target.value)}
				error={emailState.error || ''}
			/>
			<Button
				variant="secondary"
				size="small"
				mt={16}
				sx={{ minWidth: '87px !important' }}
				loading={isLoading(emailState)}
				onClick={() => {
					emailState.step === 'edit' ? onEditEmail() : onAddEmail();
				}}
			>
				Add email
			</Button>
		</>
	);
}
