import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { ERROR_EVENT_NAME, ERROR_MAP } from './constants';
import type {
	RemoveRecommendedDecisionMakerMutation,
	RemoveRecommendedDecisionMakerMutationVariables,
} from './removeRecommendedDecisionMaker.graphql';
import { RemoveRecommendedDecisionMaker } from './removeRecommendedDecisionMaker.graphql';

type RemoveRecommendedDecisionMakersParams = {
	petitionId: string;
	recommendedDecisionMaker: RecommendedDecisionMaker;
} & { trackingContext: string };
type RemoveRecommendedDecisionMakersResponse = Extract<
	RemoveRecommendedDecisionMakerMutation['removeDecisionMakerFromPetition'],
	{ __typename: 'RemoveDecisionMakerFromPetitionSuccess' }
>;
type RecommendedDecisionMaker = {
	normalizedId: string;
	canonicalPetitionTargetId: string | null;
	name: string;
	type: 'PERSON' | 'ORG';
	title: string | null;
	photoUrl: string | null;
};

// eslint-disable-next-line max-statements
export async function editPetitionDecisionMakers(
	{ petitionId, recommendedDecisionMaker, trackingContext }: RemoveRecommendedDecisionMakersParams,
	{ gql: { fetch }, errorReporter }: UtilityContext,
): Promise<RemoveRecommendedDecisionMakersResponse> {
	const params = { context: trackingContext, event: ERROR_EVENT_NAME };

	if (!recommendedDecisionMaker.canonicalPetitionTargetId) {
		const error = new Error(ERROR_MAP.BAD_REQUEST);
		void errorReporter.report({ error, params });
		throw error;
	}

	const petitionInput = {
		id: petitionId,
		decisionMaker: {
			id: recommendedDecisionMaker.canonicalPetitionTargetId,
		},
	};
	try {
		const { data } = await fetch<
			RemoveRecommendedDecisionMakerMutation,
			RemoveRecommendedDecisionMakerMutationVariables
		>({
			query: RemoveRecommendedDecisionMaker,
			variables: {
				input: petitionInput,
			},
		});

		if (data?.removeDecisionMakerFromPetition.__typename !== 'RemoveDecisionMakerFromPetitionSuccess') {
			const error = new Error(ERROR_MAP[data?.removeDecisionMakerFromPetition.type ?? 'UNKNOWN_ERROR']);
			void errorReporter.report({ error, params });
			throw error;
		}

		if (!data?.removeDecisionMakerFromPetition.success) {
			const error = new Error(ERROR_MAP.GRAPHQL_ERROR);
			void errorReporter.report({ error, params });
			throw error;
		}

		return data?.removeDecisionMakerFromPetition;
	} catch (err) {
		const error = new Error(ERROR_MAP.UNKNOWN_ERROR);
		void errorReporter.report({ error, params });
		throw error;
	}
}
